import React from 'react';

const Error = (props) => {
  return (
    <div className="portfolioFlex">
      <h1>oops 404 error!</h1>
      <p>This page is underconstruction! Check back soon for updates. I&apos;m constantly updating this site :))</p>
    </div>

  );
};

export default Error;
